import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import NotFound from "page/NotFound";
import {
  HOMEPAGE,
  PAGE_404_PATH,
  OUR_STORY_PAGE,
} from "constant/appPath";
import Application from "page/Application";
import Home from "page/Application/Home";
import Page404 from "page/Page404";
import OurStory from "page/Application/OurStory";

function App() {

  return (
    <Router>
      <Routes>
        {/* Routes public */}
        <Route path={HOMEPAGE} element={<Application />}>
          <Route index element={<Home />} />
          <Route path={OUR_STORY_PAGE} element={<OurStory />} />
        </Route>
        <Route path={PAGE_404_PATH} element={<Page404 />} />
        {/* Not Found Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
