import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "page/Application/OurStory/OurStory.module.scss";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getListAllAlbum } from "api/app/album.api";
import bgContent from "assets/our_story/home_bg.png";


import image from "assets/home/drip_drip.png";

const cx = classNames.bind(styles);

const OurStory = () => {
    const [data, setData] = useState({ array1: [], array2: [] });

    const fetchDataAlbum = async () => {
        const response = await getListAllAlbum();
        setData(response);
        console.log("Check images", response);
    };

    const handleButtonClick = () => {
        window.open("/", "_blank");
    };

    useEffect(() => {
        fetchDataAlbum();
    }, []);
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <>
            <div className={cx("backgroup-content")}>
                <img src={bgContent} className={cx("bg-content-img")} />
            </div>
            <div className={cx("content-detail-we")}>
                <div className={cx("content-detail-we-text-hd")}>Welcome</div>
                <div className={cx("content-detail-we-col")}>
                </div>
                <span className={cx("content-detail-we-text")}>
                    Drip Drip is all about making your day brighter, one cup of coffee at a time.We're here to brew up happiness and spread smiles. Join us for a cozy atmosphere and a delicious cup of joy to kickstart your day!
                </span>
            </div>
            <div className={cx("content-detail-slide")}>
                <div className={cx("content-detail-slide-div")}>
                    <Slider {...settings}>
                        {data.array1.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Slide ${index}`} style={{ width: '100%', textAlign: "center" }} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <span className={cx("content-detail-slide-text")}>
                    Drip Drip Coffeehouse is a women-owned and minority-operated Vietnamese coffee shop in the heart of Seattle's Cherry Hill neighborhood. At our cozy café, we source the finest Robusta and Arabica beans from Lam Dong, Vietnam, to bring you exceptional flavors and quality in every cup. Join us for a warm and welcoming coffee experience, where community and culture come together over a shared love for great coffee.
                </span>
            </div >
            <div className={cx("content-detail-image")}>
                {data.array2.map((image, index) => (
                    <div className={cx("content-detail-image-item")} key={index}>
                        <img src={image} alt="Image 2" className={cx("img-item-0")} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default OurStory;
