import React from "react";
import { Outlet } from "react-router-dom";
import Header from "layouts/components/Header";
import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
import { Helmet } from 'react-helmet';

const cx = classNames.bind(styles);

function Application() {

  const handleButtonClickOrderOnline = () => {
    window.open(
      "https://www.clover.com/online-ordering/drip-drip-coffeehouse-seattle",
      "_blank"
    );
  };

  const handleButtonClick = () => {
    window.open("/", "_blank");
  };

  return (
    <div class="site-wrapper">
      {/* <Helmet>
        <title>Tiêu đề trang của bạn</title>
        <meta name="description" content="Mô tả về trang của bạn." />
        <meta property="og:title" content="Tiêu đề trang của bạn" />
        <meta property="og:description" content="Mô tả về trang của bạn" />
        <meta property="og:image" content="https://media.istockphoto.com/id/1460655983/vi/anh/m%E1%BB%99t-con-s%C3%B3ng-bi%E1%BB%83n-v%C3%A0-t%E1%BA%A7m-nh%C3%ACn-tr%C3%AAn-b%C3%A3i-bi%E1%BB%83n-n%E1%BB%81n-t%E1%BB%B1-nhi%C3%AAn.jpg?s=1024x1024&w=is&k=20&c=bxPAnKyS9G5Ead2zmf5qEW1OluqRyiLK53NoROiRYcc=" />
        <meta property="og:url" content="https://example.com/your-page-url" />        
      </Helmet> */}
      <Header />
      <div className={cx("content")}>
        <Outlet />
        <div className={cx("content-detail-we-footer")}>
          <span className={cx("content-detail-we-text-hd-footer")}>
            Brewing Happiness, Spreading Smiles
          </span>
          <span className={cx("content-detail-we-text-footer")}>
            355 15th Ave, Seattle, WA, 98122, Washington
          </span>
          <span className={cx("content-detail-we-text-footer")}>
            (206) 485-7030
          </span>
          <button
            onClick={handleButtonClickOrderOnline}
            className={cx("content-detail-we-btn-footer")}
          >
            order online
          </button>
        </div>
      </div>

    </div>
  );
}

export default Application;
