import React, { useState, useEffect } from "react";
import logo from "assets/logo/logo192-nobg.png";
import classNames from "classnames/bind";
import styles from "layouts/components/Header/Header.module.scss";
import { OUR_STORY_PAGE } from "constant/appPath";
import { Link } from "react-router-dom";
import instagram from "assets/img/header/instagram.png";

const cx = classNames.bind(styles);

const emailAddress = "Dripdripcoffeehouse@gmail.com";
const instagramLink = 'https://www.instagram.com/DripDrip.coffeehouse';
const facebookLink = 'https://www.facebook.com/profile.php?id=61557228508811';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClickOrderOnline = () => {
    window.open(
      "https://www.clover.com/online-ordering/drip-drip-coffeehouse-seattle",
      "_blank"
    );
  };
  return (
    <header className={cx("header", { scrolled })}>
      <div className={cx("logo")}>
        {/* Thay thế đường dẫn trong src bằng đường dẫn tới file logo của bạn */}
        <Link to="/"><img src={logo} alt="Logo" /></Link>
      </div>
      <nav className={cx("menu")}>
        {/* Thêm các mục menu của bạn ở đây */}
        <ul>
          <li>
            <Link to={OUR_STORY_PAGE}>our story</Link>
          </li>
          <li>
            <a
              onClick={handleButtonClickOrderOnline}
              className={cx("click-url")}
            >
              order online
            </a>
          </li>
          <li>
            <a href={facebookLink} target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="#231F20" d="M22.7 2H1.3C0.6 2 0 2.6 0 3.3v19.4C0 23.4 0.6 24 1.3 24h11.7v-9.3H10V12h3V9.6c0-3.1 1.9-4.8 4.7-4.8 1.3 0 2.4.1 2.7.2v3.3h-1.8c-1.5 0-1.8.7-1.8 1.8v2.3h3.5l-.4 3.5h-3.1V24h6.1c.7 0 1.3-.6 1.3-1.3V3.3c0-.7-.6-1.3-1.3-1.3z" />
              </svg>
            </a>
          </li>
          <li>
            <a href={instagramLink} target="_blank">
              <img src={instagram} style={{ width: "25px" }} />
            </a>
          </li>
          <li>
            <a href={`mailto:${emailAddress}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="#231F20" d="M22 4H2C0.9 4 0.01 4.9 0.01 6L0 18c0 1.1 0.89 2 1.99 2H22c1.1 0 2-0.9 2-2V6C24 4.9 23.1 4 22 4zM22 18H2V8l10 5 10-5v10z" />
                <path fill="none" d="M0 0h24v24H0z" />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
