import React, { useState, useEffect } from "react";
import { getListAllCategory } from "api/app/category.api";
import classNames from "classnames/bind";
import styles from "page/Application/Home/Home.module.scss";
import { OUR_STORY_PAGE } from "constant/appPath";
import bgContent from "assets/home/home_bg.webp";
import textHeader from "assets/home/text-header.png";


import image2 from "assets/home/image2.png";
import image4 from "assets/home/image4.png";
import image6 from "assets/home/image6.png";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const Home = () => {
  const [listCategory, setListCategory] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  const fetchDataCategory = async () => {
    const response = await getListAllCategory();
    setListCategory(response);
  };

  const handleButtonClick = () => {
    window.open(OUR_STORY_PAGE, "_blank");
  };

  useEffect(() => {
    fetchDataCategory();
  }, []);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className={cx("backgroup-content")}>
        <img src={bgContent} className={cx("bg-content-img")} />
        <img src={textHeader} className={cx("bg-content-img-text")} />
      </div>
      <div className={cx("content-detail-we")}>
        <span className={cx("content-detail-we-text")}>
          Drip Drip is all about making your day brighter, one cup of coffee at
          a time. We're here to brew up happiness and spread smiles. Join us for
          a cozy atmosphere and a delicious cup to kickstart your day!
        </span>
        <Link
          to={OUR_STORY_PAGE}
          className={cx("content-detail-we-btn")}
        >
          discover our story
        </Link>
      </div>
      <div className={cx("content-detail-image")}>
        <div className={cx("content-detail-image-item")}>
          <div className={cx("content-detail-text-item")}>
            Phin Brewed Coffee
          </div>
        </div>
        <div className={cx("content-detail-image-item")}>
          <img src={image2} alt="Image 2" className={cx("img-item")} />
        </div>
        <div className={cx("content-detail-image-item")}>
          <div className={cx("content-detail-text-item")}>
            Non-coffee Sips
          </div>
        </div>
        <div className={cx("content-detail-image-item")}>
          <img src={image4} alt="Image 4" className={cx("img-item-4")} />
        </div>
        <div className={cx("content-detail-image-item")}>
          <div className={cx("content-detail-text-item")}>
            Espresso Station
          </div>
        </div>
        <div className={cx("content-detail-image-item")}>
          <img src={image6} alt="Image 6" className={cx("img-item-6")} />
        </div>
      </div>
      {/* <div className={cx("content-detail-tab-category")}>
        <div className={cx("content-detail-tab-category-title")}>MENU</div>
        <div className={cx("content-detail-tab-category-content")}>
          <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
            <Row className={cx("tab-title-category")}>
              <Col>
                <Nav
                  variant="pills"
                  className="flex-row"
                  onSelect={(index) => handleTabChange(index)}
                >
                  {listCategory.map((category, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={index}>{category.name}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <Tab.Content>
                  {listCategory.map((category, index) => (
                    <Tab.Pane key={index} eventKey={index} className="tab-pane">
                      <ListProductByCategory category={category} />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div> */}
    </>
  );
};

export default Home;
