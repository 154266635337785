// authentication router
const SIGN_IN_PATH = "/auth/";
const SIGN_UP_PATH = "register";

const PAGE_404_PATH = "/error";

const HOMEPAGE = "/";
const HOME_PAGE_TOP = "/home";
const OUR_STORY_PAGE = "/our-story";

export {
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    HOMEPAGE,
    PAGE_404_PATH,
    HOME_PAGE_TOP,
    OUR_STORY_PAGE,
};
